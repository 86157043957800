import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: fixed;
  overflow-y: auto;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div<{ padded?: boolean, scrollable?: boolean }>`
  background-color: white;
  width: 100%;
  max-width: 400px;
  padding: ${props => props.theme.spacing.medium};
  margin: ${props => props.scrollable ? props.theme.spacing.xLarge : 0} ${props => props.padded ? props.theme.spacing.medium : 0};
  position: relative;
  border-radius: 8px;
  max-height: ${props => props.scrollable ? `calc(100vh - ((${props.padded ? props.theme.spacing.medium : 0} + ${props.theme.spacing.xLarge}) * 2))` : 'auto'};
  overflow: ${props => props.scrollable ? 'auto' : 'visible'};
`

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.spacing.small};
  right: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`

interface ModalProps {
  onClose?: () => void
  padded?: boolean
  scrollable?: boolean
}

const Modal: React.FC<ModalProps> = ({ onClose, padded, scrollable, children }) => {
  return <ModalWrapper onClick={onClose}>
    <ModalContent onClick={(e) => e.stopPropagation()} padded={padded} scrollable={scrollable}>
      {onClose && <CloseButton onClick={onClose}>&times;</CloseButton>}
      {children}
    </ModalContent>
  </ModalWrapper>
}

export default Modal
