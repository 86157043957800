import React, { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OnboardingStep } from '../types/onboardingTypes'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'
import WeightInput, { WeightInputRef } from './WeightInput'

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Switch = styled.div`
  margin: auto;
  position: relative;
  width: 100px;
  height:  ${({ theme }) => theme.spacing.large};
  background: #d9d9d9;
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  padding: 2px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 50px;
    height: ${({ theme }) => theme.spacing.large};
    border-radius: 4px;
    top: 50%;
    left: 2px;
    background:  ${({ theme }) => theme.colors.primary};
    transform: translate(0, -50%);
  }
`

const SwitchInput = styled.input`
  display: none;

  &:checked + ${Switch} {
    &:before {
      transform: translate(100%, -50%);
    }
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: auto;
`

const Unit = styled.span< { selected: boolean }>`
  font-size: 1.125rem;
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.text)};
`

const InputLabel = styled.span`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
`

interface WeightInputStepProps {
  stepConfig: OnboardingStep
  onWeightChange: (weight: IUserWeight | undefined) => void
  setErrorMessage: (message: string) => void
  initialUnit?: IUnitSystem
  initialWeight?: IUserWeight
}

const WeightInputStep: React.FC<WeightInputStepProps> = ({ stepConfig, onWeightChange, setErrorMessage, initialUnit, initialWeight }) => {
  const { t } = useTranslation()
  const [selectedUnit, setSelectedUnit] = useState<IUnitSystem>(initialUnit ?? 'cm/kg')
  const weightInputRef = useRef<WeightInputRef | null>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    weightInputRef?.current?.clearInputs()
    setErrorMessage('')
    setSelectedUnit(e.target.checked ? 'ft/lbs' : 'cm/kg')
  }

  return (
    <CenteredContainer>
      <StepTitle>{stepConfig.wordings.title}</StepTitle>
      {stepConfig.wordings.subtitle && <StepSubtitle>{stepConfig.wordings.subtitle}</StepSubtitle>}
      <Label>
        <SwitchInput
          checked={selectedUnit === 'ft/lbs'}
          type="checkbox"
          onChange={handleChange}
        />
        <Switch >
          <InputLabel>
            <Unit selected={selectedUnit === 'cm/kg'}>{t('weight.kg')}</Unit>
            <Unit selected={selectedUnit === 'ft/lbs'}>{t('weight.lb')}</Unit>
          </InputLabel>
        </Switch>
      </Label>
      <WeightInput
        ref={weightInputRef}
        initialWeight={initialWeight}
        key={stepConfig.id}
        placeholder={stepConfig.wordings.placeholder ?? ''}
        unit={selectedUnit}
        onWeightChange={onWeightChange}
        setErrorMessage={setErrorMessage}
      />
    </CenteredContainer>
  )
}

export default WeightInputStep
