import { useAppState } from 'AppContextProvider'
import AgeInputStep from 'Components/AgeInputStep'
import BmiStep from 'Components/BMIStep'
import ContinueButton from 'Components/Button'
import EmailAlreadyInUseModal from 'Components/EmailAlreadyInUseModal'
import EmailStep from 'Components/EmailStep'
import GenderSelectStep from 'Components/GenderSelection'
import HeightInputStep from 'Components/HeightInputStep'
import HoursTrackedStep from 'Components/HoursTrackedStep'
import InfoCard from 'Components/InfoCard.component'
import InfoStep from 'Components/InfoStep'
import Loading from 'Components/Loading.component'
import MultiSelectStep from 'Components/MultiSelectStep'
import OccasionDateSelection from 'Components/OccasionDateSelection'
import PlanIsReadyStep from 'Components/PlanIsReadyStep'
import ProgressStep from 'Components/ProgressStep'
import EnhancedProgressStep from 'Components/EnhancedProgressStep'
import ReceiveEmails from 'Components/ReceiveEmails'
import ReinforcementBeforeAfter from 'Components/ReinforcementBeforeAfter'
import ReinforcementScientificMethod from 'Components/ReinforcementScientificMethod'
import TheOneStep from 'Components/TheOneStep'
import WeightInputStep from 'Components/WeightInputStep'
import { calculateBMI, getUserCountry, getUserLanguage, isValidEmail } from 'Lib'
import { getSkipEventsMap } from 'Lib/EventsUtils'
import { ActionType } from 'Reducers'
import { ONBOARDING_ROUTES_BY_STEP_ID } from 'RouteNames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useDispatch } from 'store'
import styled from 'styled-components'
import ProgressBar from '../Components/ProgressBar'
import SingleSelectStep from '../Components/SingleSelectStep'
import { ONBOARDING_STEPS, OnboardingStep } from '../types/onboardingTypes'
import LoginContainer from './Login.container'
import { FastingMotivation, SpecialOccasion } from './steps'
import { NON_ANIMATING_STEP_TYPES, TRANSITION_ANIMATION_DURATION } from 'Lib/Constants'
import { showToast } from 'Components/Toast'
import { useFeatureValue } from '@growthbook/growthbook-react'

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
`

const StepWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
  @media (max-width: ${props => props.theme.maxWidth}) {
    overflow-x: clip;
  }
`

const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.maxWidth};
  width: 100%;
  padding: 0 ${props => props.theme.responsiveSpacing.small};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
`

const InfoCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
`

const Error = styled.div`
  text-align: center;
  color: red;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: 0px;
`

export const ButtonFooter = styled.footer<{ sticky?: boolean }>`
  position: ${({ sticky: stick }) => stick ? 'sticky' : 'relative'};
  bottom: 0;
  width: 100%;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateY(100%);
  opacity: 0;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }
`

const OnboardingStepsContainer: React.FC = () => {
  const history = useHistory()
  const { appState, setAppState } = useAppState()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>()
  const [infoData, setInfoData] = useState<{ title: string, content: string, color?: string, icon?: any } | undefined>()
  const [error, setError] = useState<string | undefined>()

  const [shouldShowButton, setShouldShowButton] = useState<boolean>()
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
  const [acceptedNewsletter, setAcceptedNewsletter] = useState<boolean>(false)

  const [shouldUseImperialSystem, setShouldUseImperialSystem] = useState<boolean>()
  const lang = getUserLanguage()

  // shows the "you already have an account" modal if the used email
  // is already associated with an account on the newsletter step.
  const [emailAlreadyInUseModalOpened, setEmailAlreadyInUseModalOpened] = useState<boolean>(false)

  const goToNextStep = (shouldSkipNext?: boolean) => {
    const currentStepId = appState.currentStep?.id
    const currentIndex = currentStepId != null ? appState.stepsToShowIds!.indexOf(currentStepId) : 0

    const nextStep = findNextStep()
    // when the current index is equal or bigger than the number of steps
    // and there is no next step means that it has reached the end of
    // the onboarding, so we navigate to the payment screen
    if (currentIndex >= appState.stepsToShowIds!.length - 1 && !nextStep) {
      return navigateToPostOnboardingRoute()
    }

    const currentStepIndex = appState.stepsToShowIds?.findIndex((stepId) => stepId === currentStepId)

    if (currentStepIndex === undefined || currentStepIndex === -1) {
      return
    }

    setError('')

    let nextStepId: ONBOARDING_STEPS | undefined

    const skipEvents = getSkipEventsMap()

    if (shouldSkipNext === true) {
      const skippedStepId = appState.stepsToShowIds![currentStepIndex + 1]

      // We don't want to log the skip event for the newsletter step and the login step
      // they are part of two flows (or receive_email or existing_email_login),
      // which means that technically the user is not skipping the step, but rather
      // going to the next step of the flow
      if (skippedStepId !== 'receive_email' && skippedStepId !== 'existing_email_login') {
        logEvent(`${skipEvents[skippedStepId] ?? `skip_unk_${skippedStepId}`}`)
      }
      nextStepId = appState.stepsToShowIds![currentStepIndex + 2]
    } else {
      nextStepId = appState.stepsToShowIds![currentStepIndex + 1]
    }
    history.push(ONBOARDING_ROUTES_BY_STEP_ID[nextStepId])
  }

  const goToPrevStep = () => {
    setError('')
    history.goBack()
  }

  const isGoingBack = () => {
    if (!appState.stepsToShowIds || !appState.currentStep) { return false }
    const currentStepIndex = appState.stepsToShowIds.indexOf(appState.currentStep.id)
    const prevStepIndex = appState.prevStep != null ? appState.stepsToShowIds.indexOf(appState.prevStep.id) : 0
    return currentStepIndex <= prevStepIndex
  }

  const handleReceiveEmails = (accept: boolean) => {
    if (isValidEmail(appState.selections?.email)) {
      void handleNextStep()
      setAcceptedNewsletter(accept)
    }
  }

  const requestAnonymousSignIn = async (): Promise<void> => {
    return await new Promise((resolve, reject) => {
      dispatch({
        type: ActionType.ANONYMOUS_SIGN_IN_REQUEST,
        newsletterAccepted: acceptedNewsletter, // Only for logging
        resolve: resolve,
        reject: reject
      })
    })
  }

  const saveOnboardingData = async (): Promise<void> => {
    return await new Promise((resolve, reject) => {
      dispatch({
        type: ActionType.SAVE_ONBOARDING_DATA_REQUEST,
        onboardingData: { ...appState.selections! },
        newsletterAccepted: acceptedNewsletter,
        email: appState.selections?.email,
        resolve: resolve,
        reject: reject
      })
    })
  }

  const navigateToPostOnboardingRoute = () => {
    logEvent('web_onboarding_completed')
    dispatch({ type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE })
  }

  useEffect(() => {
    if (!appState.stepsToShowIds) { return }
    if (appState.stepsToShowIds.length === 0 || !appState.currentStep) { return }

    handleIsButtonDisabled()
    setShowProgressBar(appState.currentStep.type !== 'custom')
    setError(undefined)

    // The height info data is controlled by the height step
    if (appState.currentStep.id !== 'height') {
      setInfoData(appState.currentStep.wordings.info)
    }
    setShouldShowButton((appState.currentStep.type !== 'single-select' && appState.currentStep.type !== 'custom') || appState.currentStep.allowNext)

    window.scrollTo(0, 0)
  }, [appState.currentStep])

  useEffect(() => {
    const userSelectedUnit = appState.selections?.height?.original?.unit
    const isUs = getUserCountry() === 'US'

    setShouldUseImperialSystem(userSelectedUnit === 'ft/in' || isUs)
  }, [])

  const handleIsButtonDisabled = (force?: boolean) => {
    let isButtonDisabled = false
    if (!appState.currentStep) {
      isButtonDisabled = true
    } else {
      if (!appState.selections) {
        return
      }
      const hasSelectionsForStep = appState.selections[appState.currentStep.id] !== undefined
      const isSelectionValidForStep = isSelectionValid()

      if (force) {
        isButtonDisabled = true
      } else if (appState.currentStep.allowNext != null) {
        isButtonDisabled = !appState.currentStep.allowNext
      } else if (!isSelectionValidForStep) {
        isButtonDisabled = true
      } else if (!hasSelectionsForStep) {
        isButtonDisabled = true
      }
    }

    setIsButtonDisabled(isButtonDisabled)
  }

  const isSelectionValid = () => {
    if (!appState.currentStep) { return }

    if (appState.currentStep.id === 'perfect_weight') {
      return isPerfectWeightValid()
    }

    return true
  }

  const isPerfectWeightValid = () => {
    if (!appState.currentStep) { return }

    const selections = appState.selections
    const height = selections?.height?.inCm.value as number
    const perfectWeight = selections?.perfect_weight?.inKg?.value

    if (height && perfectWeight) {
      const bmi = calculateBMI(height, perfectWeight)
      if (bmi < 18.5) {
        return false
      }
    }
    return true
  }

  const setSelections = (stepId: ONBOARDING_STEPS, value: any) => {
    logEvent('web_setSelections', { stepId, value })

    setAppState((prev) => {
      const selections = { ...prev.selections }
      selections[stepId] = value
      return { ...prev, selections: selections }
    })
  }

  useEffect(() => {
    handleIsButtonDisabled()
  }, [appState.selections])

  const findNextStep = () => {
    if (!appState.stepsToShowIds || !appState.steps) return null
    const nextIndex = appState.currentStep?.id != null ? appState.stepsToShowIds.indexOf(appState.currentStep?.id) + 1 : 0

    return nextIndex < appState.steps.length ? appState.steps[nextIndex] : null
  }

  const handleNextStep = async (shouldSkipNext?: boolean) => {
    setError(undefined)

    if (appState?.currentStep?.id === 'email') {
      checkIfEmailIsAlreadyInUse()
      return
    }

    if (appState?.currentStep?.id === 'plan_is_ready') {
      try {
        await requestAnonymousSignIn()
        await saveOnboardingData()
      } catch (error) {
        const errorMessage = t(`FirebaseError.${error.code}`, { ns: 'translation', defaultValue: t('FirebaseError.auth/unknown', { ns: 'translation' }) })
        showToast('error', errorMessage)

        return
      }
    }

    const currentIndex = appState.currentStep !== undefined ? appState.stepsToShowIds!.indexOf(appState.currentStep.id) : 0

    if (currentIndex <= appState.stepsToShowIds!.length - 1) {
      goToNextStep(shouldSkipNext)
    }
  }

  const getInitialWeightUnit = (step: OnboardingStep): IUnitSystem => {
    if (step.id === 'perfect_weight') {
      const originalWeightUnit = appState?.selections?.perfect_weight?.original?.unit
      if (originalWeightUnit) return originalWeightUnit
    } else if (step.id === 'weight') {
      const originalWeightUnit = appState?.selections?.weight?.original?.unit
      if (originalWeightUnit) return originalWeightUnit
    }

    return shouldUseImperialSystem ? 'ft/lbs' : 'cm/kg'
  }

  const checkIfEmailIsAlreadyInUse = () => {
    dispatch({
      type: ActionType.CHECK_EMAIL_EXISTS_REQUEST,
      email: appState.selections?.email,
      resolve: (emailExists: boolean) => {
        if (emailExists) {
          logEvent('web_openEmailExistsModal')
          setEmailAlreadyInUseModalOpened(true)
        } else {
          setEmailAlreadyInUseModalOpened(false)
          // if the email is not already in use, we can go to the newsletter step
          // without showing the modal and the login step
          goToNextStep(true)
        }
      }
    })
  }

  const handleSelection = (stepId: ONBOARDING_STEPS, selectedOption: any) => {
    setError('')
    setSelections(stepId, selectedOption)
  }

  const handleSkip = (stepId: ONBOARDING_STEPS) => {
    setError('')
    setSelections(stepId, undefined)
    goToNextStep()
  }

  const clearSelection = (stepId: ONBOARDING_STEPS) => {
    setAppState((prev) => {
      const selections = { ...prev.selections }
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete selections[stepId]
      return { ...prev, selections: selections }
    })
  }

  const handleCustomBackButtonClick = () => {
    goToPrevStep()
  }

  const handlePerfectWeightCard = (weight: IUserWeight | undefined) => {
    const currentWeight = appState.selections?.weight.inKg.value
    const goalWeight = weight?.inKg.value

    if (weight) {
      const newBmi = (weight?.inKg.value / ((appState.selections?.height.inCm.value / 100) * (appState.selections?.height.inCm.value / 100)))

      if (newBmi && newBmi < 18.5) {
        setInfoData(getUnderweightExplanation())
        handleIsButtonDisabled(false)
        return
      }
    }

    if (goalWeight) {
      const percentage = Math.round(((currentWeight - goalWeight) / currentWeight) * 100)
      const info = getPerfectWeightInfo(percentage)
      setInfoData(info)
    } else {
      setInfoData(undefined)
    }
  }

  const getUnderweightExplanation = () => {
    return {
      color: '#fefbe7',
      title: t('perfect_weight.infoCard.underweight.title'),
      content: t('perfect_weight.infoCard.underweight.content')
    }
  }

  const handleBmiCard = (weight: IUserWeight | undefined) => {
    if (!weight) {
      setInfoData(undefined)
      return
    }
    const currentWeight = weight?.inKg.value
    const height = appState.selections?.height.inCm.value / 100

    const bmi = (currentWeight / (height * height))

    if (bmi) {
      const info = getBmiInfo(bmi)
      setInfoData(info)
    } else {
      setInfoData(undefined)
    }
  }

  const handleHeightCard = (height: IUserHeight | undefined, stepConfig: OnboardingStep) => {
    if (!height) {
      setInfoData(undefined)
    } else {
      setInfoData(stepConfig.wordings.info)
    }
  }

  const handleWeightCard = (step: OnboardingStep, weight: IUserWeight | undefined) => {
    if (step.id === 'perfect_weight') {
      handlePerfectWeightCard(weight)
    } else if (step.id === 'weight') {
      handleBmiCard(weight)
    }
  }

  const getPerfectWeightInfo = (percentage: number) => {
    if (percentage < 0 || percentage === 0) {
      return undefined
    } else if (percentage > 0 && percentage < 5) {
      return {
        color: '#f2efee',
        title: t('perfect_weight.infoCard.oneToFive.title'),
        content: t('perfect_weight.infoCard.oneToFive.content')
      }
    } else if (percentage < 10) {
      return {
        color: '#f5faf7',
        title: t('perfect_weight.infoCard.sixToTen.title'),
        content: t('perfect_weight.infoCard.sixToTen.content')
      }
    } else if (percentage < 15) {
      return {
        color: '#f5faf7',
        title: t('perfect_weight.infoCard.elevenToFifteen.title'),
        content: t('perfect_weight.infoCard.elevenToFifteen.content')
      }
    } else {
      return {
        color: '#f5faf7',
        title: t('perfect_weight.infoCard.sixteenOrMore.title'),
        content: t('perfect_weight.infoCard.sixteenOrMore.content')
      }
    }
  }

  const getBmiInfo = (bmi: number) => {
    const roundedBmi = Math.round(bmi * 10) / 10
    const formattedBmi = roundedBmi.toLocaleString(lang)
    if (roundedBmi < 18.5) {
      return {
        color: '#fefbe7',
        title: t('weight.infoCard.underweight.title', { bmi: formattedBmi }),
        content: t('weight.infoCard.underweight.content')
      }
    } else if (roundedBmi <= 24.9) {
      return {
        color: '#f5faf7',
        title: t('weight.infoCard.normal.title', { bmi: formattedBmi }),
        content: t('weight.infoCard.normal.content')
      }
    } else if (roundedBmi <= 29.9) {
      return {
        color: '#fefbe7',
        title: t('weight.infoCard.overweight.title', { bmi: formattedBmi }),
        content: t('weight.infoCard.overweight.content')
      }
    } else {
      return {
        color: '#fcf3f3',
        title: t('weight.infoCard.obese.title', { bmi: formattedBmi }),
        content: t('weight.infoCard.obese.content')
      }
    }
  }

  const getTypeOfEater = () => {
    const selections = appState.selections

    // gourmand, meal_skipper, healthy
    const breakfastTime = selections?.breakfast_time
    const lunchTime = selections?.lunch_time
    const dinnerTime = selections?.dinner_time

    // home, outside, both
    const homeOrOutside = selections?.home_or_outside

    if (breakfastTime && lunchTime && dinnerTime) {
      // If two meals are skipped, the user is a meal skipper
      if ((breakfastTime === 'skip' && lunchTime === 'skip') ||
        (breakfastTime === 'skip' && dinnerTime === 'skip') ||
        (lunchTime === 'skip' && dinnerTime === 'skip')) {
        return 'meal_skipper'
      }

      // If the user eats at least 2 meals a day and eats at home, he is healthy
      if ((breakfastTime !== 'skip' && lunchTime !== 'skip') ||
        (breakfastTime !== 'skip' && dinnerTime !== 'skip') ||
        (lunchTime !== 'skip' && dinnerTime !== 'skip')) {
        if (homeOrOutside === 'home') {
          return 'healthy'
        }
      }

      // If the user eats at least 2 meals a day, he is a gourmand
      if (breakfastTime !== 'skip' && lunchTime !== 'skip' && dinnerTime !== 'skip') {
        return 'gourmand'
      }
    }

    return 'gourmand'
  }

  const getFastingMotivation = () => {
    const selections = appState.selections
    const fastingMotivation = selections?.fasting_motivation

    if (fastingMotivation === FastingMotivation.imSerious) {
      return 'high'
    } else if (fastingMotivation === FastingMotivation.iWantToTry) {
      return 'average'
    } else if (fastingMotivation === FastingMotivation.justCurious) {
      return 'low'
    }

    return 'average'
  }

  const getLifeStyle = () => {
    // energetic, active, lazy
    const selections = appState.selections

    const workActivityLevel = selections?.work_activity_level // farily_inactive, physical_labor, bit_of_both
    const walkingTime = selections?.walking_time // less_than_20, 20_to_60, more_than_60

    if (workActivityLevel === 'physical_labor' && walkingTime === 'more_than_60') {
      return 'active'
    } else if (workActivityLevel === 'farily_inactive' || walkingTime === 'less_than_20') {
      return 'inactive'
    } else {
      return 'energetic'
    }
  }

  const getCurrentStepComponent = (step: OnboardingStep) => {
    if (step.type === 'custom' && step.id === 'tracked_hours') {
      return <HoursTrackedStep />
    } else if (step.id === 'gender') {
      return <GenderSelectStep onSelectionChange={
        (selection) => {
          handleSelection(step.id, selection)
          void handleNextStep()
        }
      } />
    } else if (step.type === 'single-select') {
      return <SingleSelectStep
        stepConfig={step}
        onSelectionChange={(selection) => {
          handleSelection(step.id, selection)

          if (step.id === 'special_occasion' && selection === SpecialOccasion.noEvent) {
            void handleNextStep(true)
          } else {
            void handleNextStep()
          }
        }
        }
        onSkip={() => {
          handleSkip(step.id)
        }} />
    } else if (step.type === 'multiple-select') {
      return <MultiSelectStep
        stepConfig={step}
        onSelectionChange={(selection) => {
          handleSelection(step.id, selection)
        }}
        onSkip={() => {
          handleSkip(step.id)
        }} />
    } else if (step.type === 'height') {
      return <HeightInputStep
        stepConfig={step}
        initialUnit={shouldUseImperialSystem ? 'ft/lbs' : 'cm/kg'}
        initialHeight={appState.selections?.height}
        setErrorMessage={(error) => {
          return setError(error)
        }}
        onHeightChange={(height) => {
          handleHeightCard(height, step)
          if (height) {
            if (height.original.unit === 'ft/lbs') {
              setShouldUseImperialSystem(true)
            } else {
              setShouldUseImperialSystem(false)
            }
            handleSelection(step.id, height)
          } else {
            clearSelection(step.id)
          }
        }} />
    } else if (step.type === 'weight') {
      return <WeightInputStep
        key={step.id}
        stepConfig={step}
        initialUnit={getInitialWeightUnit(step)}
        initialWeight={step.id === 'perfect_weight' ? appState?.selections?.perfect_weight : appState?.selections?.weight}
        setErrorMessage={(error) => {
          if (error) {
            setInfoData(undefined)
          }
          return setError(error)
        }}
        onWeightChange={(weight) => {
          handleWeightCard(step, weight)
          if (weight) {
            handleSelection(step.id, weight)
          } else {
            clearSelection(step.id)
          }
        }} />
    } else if (step.type === 'age') {
      return <AgeInputStep
        stepConfig={step}
        initialValue={appState.selections?.[step.id]}
        setErrorMessage={setError}
        onAgeChange={(age) => {
          if (age) {
            handleSelection(step.id, age)
          } else {
            clearSelection(step.id)
          }
        }
        } />
    } else if (step.id === 'special_occasion_date') {
      return <OccasionDateSelection
        onNext={(date) => {
          handleSelection(step.id, date)
          void handleNextStep()
        }} />
    } else if (step.id === 'progress') {
      const progressScreenVersion = useFeatureValue('progress-screen-version', 'enhanced')
      if (progressScreenVersion === 'enhanced') {
        return <EnhancedProgressStep
          whenDone={handleNextStep}
        />
      } else {
        return <ProgressStep
          duration={15000}
          whenDone={handleNextStep}
        />
      }
    } else if (step.id === 'plan_is_ready') {
      return <PlanIsReadyStep
        key={step.id}
        currentWeight={appState.selections?.weight}
        goalWeight={appState.selections?.perfect_weight}
      />
    } else if (step.id === 'reinforcement_before_after') {
      return <>
        <InfoStep stepConfig={step} />
        <ReinforcementBeforeAfter gender={appState.selections?.gender} />
      </>
    } else if (step.id === 'reinforcement_scientific') {
      return <>
        <InfoStep stepConfig={step} />
        <ReinforcementScientificMethod />
      </>
    } else if (step.id === 'receive_email') {
      return <ReceiveEmails
        onNext={(accepted) => {
          handleReceiveEmails(accepted)
          handleSelection('receive_email', accepted)
        }}
      />
    } else if (step.id === 'bmi') {
      return <BmiStep
        weight={appState.selections?.weight.inKg?.value ?? 70}
        height={(appState.selections?.height?.inCm?.value ?? 170) / 100}
        typeOfEater={getTypeOfEater()}
        fastingMotivation={getFastingMotivation()}
        lifestyle={getLifeStyle()}
      />
    } else if (step.id === 'the_one_plan') {
      return <>
        <TheOneStep
          currentWeight={appState.selections?.weight}
          goalWeight={appState.selections?.perfect_weight}
          specialOccasion={appState.selections?.special_occasion}
        />
      </>
    } else if (step.type === 'email') {
      return <EmailStep
        initialEmail={appState.selections?.email}
        onSubmit={() => {
          if (isValidEmail(appState.selections?.[step.id])) {
            void handleNextStep()
          }
        }}
        onEmailChanged={function (email) {
          if (isValidEmail(email)) {
            handleIsButtonDisabled(true)
            handleSelection('email', email)
          } else {
            handleIsButtonDisabled(false)
            handleSelection('email', undefined)
          }
        }}
      />
    } else if (step.id === 'existing_email_login') {
      const email = appState.selections?.email
      return <LoginContainer
        email={email}
        onLoginSuccess={() => void handleNextStep(true)}
      />
    }
  }

  const getAnimation = () => {
    const currentStep = appState.currentStep
    if (!currentStep || !appState.steps) return 'no-transition'

    const currentStepIndex = appState.steps.findIndex((step) => step.id === currentStep.id)
    const nextStep = appState.steps[currentStepIndex + 1]
    const previousStep = appState.steps[currentStepIndex - 1]

    if (
      !NON_ANIMATING_STEP_TYPES[currentStep.type] &&
      !NON_ANIMATING_STEP_TYPES[nextStep?.type] &&
      !NON_ANIMATING_STEP_TYPES[previousStep?.type]
    ) {
      return 'step-transition'
    } else {
      return 'no-transition'
    }
  }

  return (
    (appState.currentStep && appState.steps) ? (
      <Center>
        <Container>
          {showProgressBar && (
            <ProgressBar
              currentStep={appState.stepsIdsToShowInProgressBar!.indexOf(appState.currentStep.id) + 1}
              totalSteps={appState.stepsIdsToShowInProgressBar!.length}
              barrierSteps={[
                appState.stepsIdsToShowInProgressBar!.indexOf('reinforcement_scientific') + 1,
                appState.stepsIdsToShowInProgressBar!.indexOf('reinforcement_before_after') + 1
              ]}
              onBack={handleCustomBackButtonClick}
            />
          )}
          <StepWrapper>
            <TransitionGroup>
              <CSSTransition
                key={appState.currentStep.id}
                timeout={getAnimation() === 'no-transition' ? 0 : TRANSITION_ANIMATION_DURATION}
                classNames={getAnimation()}
                unmountOnExit
              >
                <StepContentWrapper>
                  {getCurrentStepComponent(appState.currentStep)}
                  {error && <Error>{error}</Error>}
                  <InfoCardWrapper className={infoData ? 'active' : ''}>
                    {infoData && (
                      <InfoCard color={infoData.color} title={infoData.title} content={infoData.content} />
                    )}
                  </InfoCardWrapper>
                  <ButtonFooter
                    // By default, the button is sticky. If you want to disable it, set stickyButton to false in the step config
                    sticky={appState.currentStep.stickyButton ?? true}
                    className={shouldShowButton ? 'active' : ''}
                  >
                    {
                      shouldShowButton && (
                        <ContinueButton
                          disabled={isButtonDisabled}
                          onClick={handleNextStep}
                          margin='20px 0 20px auto'
                        >
                          {t('continueButton', { ns: 'onboarding' })}
                        </ContinueButton>
                      )
                    }
                  </ButtonFooter>
                </StepContentWrapper >
              </CSSTransition>
            </TransitionGroup>
          </StepWrapper>
        </Container >
        {emailAlreadyInUseModalOpened && (
          <EmailAlreadyInUseModal
            onLoginTap={() => {
              logEvent('web_confirmEmailExistsModal')
              setEmailAlreadyInUseModalOpened(false)
              goToNextStep()
            }}
            onClose={() => {
              logEvent('web_cancelEmailExistsModal')
              setEmailAlreadyInUseModalOpened(false)
            }}
          />
        )}
      </Center >
    ) : (
      <Loading />
    )
  )
}

export default OnboardingStepsContainer
