import * as Sentry from '@sentry/react'
import { getCurrency, getUserLanguage } from 'Lib'
import { ActionType } from 'Reducers'
import { ILoadProducts, IUpdateSubscription, IUpdateSubscriptionOfferDecline } from 'Reducers/PaymentPersistingRedux'
import { fetchStripePrices, updateSubscription } from 'Services'
import { setGrowthbookAttributes } from 'Services/GrowthBook.service'
import { all, call, delay, put, takeEvery, takeLatest } from 'typed-redux-saga'

export function * loadProductsSaga ({ groupId, isRetry = false }: ILoadProducts) {
  try {
    yield call(logEvent, 'web_fetchStripePrices', { groupId, isRetry })

    const currency: ReturnType<typeof getCurrency> = yield call(getCurrency)
    const { data } = yield call(fetchStripePrices, groupId, currency)

    if (data.countryCode) {
      localStorage.setItem('countryCode', data.countryCode)

      yield call(setGrowthbookAttributes, {
        country: data.countryCode
      })
    }

    localStorage.setItem('region', data.region ?? 'unknown')

    yield put({ type: ActionType.MERGE_PRODUCTS, groupId, prices: data.prices })
  } catch (error) {
    logEvent('web_fetchStripePricesError', { error, groupId, isRetry })
    Sentry.captureException(error)

    yield delay(3000)
    yield put({ type: ActionType.LOAD_PRODUCTS, groupId, isRetry: true })
  }
}

export function * updateSubscriptionSaga ({ priceId, stepId }: IUpdateSubscription) {
  yield put({ type: ActionType.SET_APP_BLOCKED })

  try {
    yield call(logEvent, 'web_updateSubscriptionRequest', { stepId, priceId })

    const lang: ReturnType<typeof getUserLanguage> = yield call(getUserLanguage)
    yield call(updateSubscription, priceId, false, lang)

    yield put({ type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE })

    yield call(logEvent, 'web_updateSubscriptionSuccess', { stepId, priceId })
  } catch (error) {
    yield call(logEvent, 'web_fetchStripePricesError', { stepId, priceId, errorMessage: error.message, errorCode: error.code })
  }

  yield put({ type: ActionType.SET_APP_UNBLOCKED })
}

export function * declineUpdateSubscriptionSaga ({ stepId }: IUpdateSubscriptionOfferDecline) {
  yield call(logEvent, 'web_updateSubscriptionOfferDecline', { stepId })
  yield put({ type: ActionType.HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE })
}

export function * PaymentSagas() {
  yield all([
    takeEvery(ActionType.LOAD_PRODUCTS, loadProductsSaga), // This takes every because we might want to fetch more than 1 product group at the same time
    takeLatest(ActionType.UPDATE_SUBSCRIPTION, updateSubscriptionSaga),
    takeLatest(ActionType.DECLINE_UPDATE_SUBSCRIPTION_OFFER, declineUpdateSubscriptionSaga)
  ])
}
