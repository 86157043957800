import { axios } from 'Fetcher'
import { firebaseServicePromise } from 'store'

export const ApiService = {
  fetchJWT: async (futoken: string): Promise<{ error?: Error, token?: string }> => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/api/getJWT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          futoken
        }
      })

      return { token: res.data.data.token }
    } catch (error: any) {
      return { error }
    }
  },
  generateCustomToken: async (): Promise<{ customToken?: string }> => {
    const firebaseService = await firebaseServicePromise
    const idToken = await firebaseService.getIdToken()
    const res = await axios({
      method: 'POST',
      url: '/api/auth/generateCustomToken',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      }
    })
    return { customToken: res.data.data.customToken }
  },
  fetchSubscription: async (): Promise<{ subscription: IStripeSubscription | IPaypalSubscription, type: 'stripe' | 'paypal' }> => {
    const firebaseService = await firebaseServicePromise
    const idToken = await firebaseService.getIdToken()

    const res = await axios({
      method: 'GET',
      url: '/api/payment/getSubscription',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0'
      }
    })

    return res.data.data
  },
  fetchSubscriptionViaSession: async (sessionId: string): Promise<{ subscription: IStripeSubscription }> => {
    const firebaseService = await firebaseServicePromise
    const idToken = await firebaseService.getIdToken()

    const res = await axios({
      method: 'GET',
      url: `/api/payment/getStripeSubscriptionViaSession/${sessionId}`,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return res.data.data
  },
  fetchStripeSubscriptionViaId: async (subscriptionId: string): Promise<{ subscription: IStripeSubscription }> => {
    const firebaseService = await firebaseServicePromise
    const idToken = await firebaseService.getIdToken()

    const res = await axios({
      method: 'POST',
      url: `/api/payment/getStripeSubscriptionViaId/${subscriptionId}`,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return res.data.data
  },
  fetchPaypalSubscriptionViaId: async (subscriptionId: string): Promise<{ subscription: IStripeSubscription }> => {
    const firebaseService = await firebaseServicePromise
    const idToken = await firebaseService.getIdToken()

    const res = await axios({
      method: 'GET',
      url: `/api/payment/getPaypalSubscriptionViaId/${subscriptionId}`,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })

    return res.data.data
  },
  getWellhubRegistrationDetails: async (wellhubRegistrationId: string): Promise<{
    wellhub_id: string
    email?: string
  }> => {
    const res = await axios({
      method: 'GET',
      url: `/api/wellhub/registrationDetails?wellhubRegistrationId=${wellhubRegistrationId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    return res.data
  },
  registerNewWellhubUser: async (gympassUserId: string): Promise<void> => {
    const firebaseService = await firebaseServicePromise
    const idToken = await firebaseService.getIdToken()

    await axios({
      method: 'POST',
      url: '/api/wellhub/registerNewWellhubUser',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      data: {
        gympass_user_id: gympassUserId
      }
    })
  },
  checkEmailExists: async (email: string): Promise<boolean> => {
    const res = await axios({
      method: 'POST',
      url: '/api/auth/checkEmailExists',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        email
      }
    })

    return res.data.emailExists
  }
}
