import { DateTime } from 'luxon'

export const isTrialing = (subscription: IStripeSubscription) =>
  subscription.trial_end
    ? DateTime.fromMillis(subscription.trial_end * 1000, { zone: 'utc' }).diffNow().as('seconds') > 0
    : false

export const getSubscriptionDuration = (subscription: IStripeSubscription): SubscriptionDuration => {
  if (isTrialing(subscription)) {
    return 'trial'
  }

  if (subscription.plan.interval === 'month') {
    switch (subscription.plan.interval_count) {
      case 1: return 'oneMonth'
      case 3: return 'threeMonths'
      case 6: return 'sixMonths'
      case 9 : return 'nineMonths'
      default: throw new Error(`Unknown interval count: ${subscription.plan.interval_count}`)
    }
  }

  throw new Error(`Unknown interval: ${subscription.plan.interval}`)
}
