import { createGlobalStyle } from 'styled-components'
import { TRANSITION_ANIMATION_DURATION } from 'Lib/Constants'
import { ButtonFooter } from 'Containers/OnboardingSteps.container'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Fira Sans, sans-serif;
    color: ${props => props.theme.colors.text};
  }

  :root {
    // This values are being overridden depending whether user going back or further in RootRouter.
    --enter-translate: 100%;
    --exit-translate: -100%;
  }

  #root {
    // Sets the page's minimum height to be as large as the user's viewport (excluding the browser's UI)
    @supports(min-height: 100dvh) {
      min-height: calc(100dvh - 16px);
    }
  }

  .step-transition-enter {
    opacity: 0;
    transform: translateX(var(--enter-translate));
  }

  .step-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity ${TRANSITION_ANIMATION_DURATION}ms, transform ${TRANSITION_ANIMATION_DURATION}ms;
  }

  /* Reset the button's state so it reanimates when another step shows up with the same Continue button */
  .step-transition-enter-active ${ButtonFooter}, .no-transition-active ${ButtonFooter} {
    transform: translateY(100%);
    opacity: 0;
    transition: none;
  }

  .step-transition-enter-done,
  .step-transition-exit-done {
    position: static;
  }

  .step-transition-exit {
    opacity: 1;
    transform: translateX(0);
  }

  .step-transition-exit-active {
    position: absolute;
    opacity: 0;
    top: 0px;
    width: 100%;
    position: absolute;
    transform: translateX(var(--exit-translate));
    transition: opacity ${TRANSITION_ANIMATION_DURATION}ms, transform ${TRANSITION_ANIMATION_DURATION}ms;

    @media screen and (max-width: 576px) {
      width: calc(100% - 16px);
    }
  }

  .no-transition-enter {
    opacity: 0;
  }

  .no-transition-enter-active {
    opacity: 1;
  }

  .no-transition-exit {
    opacity: 1
  }

  .no-transition-exit-active {
    opacity: 0
  }
`
export default GlobalStyle
