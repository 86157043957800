import React, { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OnboardingStep } from '../types/onboardingTypes'
import HeightInput, { HeightInputRef } from './HeightInput'
import StepSubtitle from './StepSubtitle.component'
import StepTitle from './StepTitle.component'

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Switch = styled.div`
  margin: auto;
  position: relative;
  width: 100px;
  height: ${({ theme }) => theme.spacing.large};
  background: #d9d9d9;
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  padding: 2px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 50px;
    height: ${({ theme }) => theme.spacing.large};
    border-radius: ${({ theme }) => theme.spacing.xsmall};
    top: 50%;
    left: 2px;
    background: ${({ theme }) => theme.colors.primary};
    transform: translate(0, -50%);
  }
`

const SwitchInput = styled.input`
  display: none;

  &:checked + ${Switch} {
    &:before {
      transform: translate(100%, -50%);
    }
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: auto;
`

const Unit = styled.span< { selected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.text)};
`

const InputLabel = styled.span`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
`

interface InputStepProps {
  stepConfig: OnboardingStep
  onHeightChange: (height: IUserHeight | undefined) => void
  setErrorMessage: (message: string) => void
  initialUnit?: IUnitSystem
  initialHeight?: IUserHeight
}

const HeightInputStep: React.FC<InputStepProps> = ({ stepConfig, onHeightChange, setErrorMessage, initialUnit, initialHeight }) => {
  const [selectedUnit, setSelectedUnit] = useState<IUnitSystem>(initialUnit ?? 'cm/kg')
  const heightInputRef = useRef<HeightInputRef | null>(null)

  const { t } = useTranslation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    heightInputRef.current?.clearInputs()
    setErrorMessage('')
    setSelectedUnit(e.target.checked ? 'ft/lbs' : 'cm/kg')
  }

  return (
    <CenteredContainer>
      <StepTitle>{stepConfig.wordings.title}</StepTitle>
      {!!stepConfig.wordings.subtitle && <StepSubtitle>{stepConfig.wordings.subtitle}</StepSubtitle>}
      <Label>
        <SwitchInput
          checked={selectedUnit === 'ft/lbs'}
          type="checkbox"
          onChange={handleChange}
        />
        <Switch >
          <InputLabel>
            <Unit selected={selectedUnit === 'cm/kg'}>{t('height.cm')}</Unit>
            <Unit selected={selectedUnit === 'ft/lbs'}>{t('height.ft')}</Unit>
          </InputLabel>
        </Switch>
      </Label>
      <HeightInput
        placeholder={stepConfig.wordings.placeholder ?? ''}
        unit={selectedUnit}
        initialHeight={initialHeight}
        onHeightChange={onHeightChange}
        setErrorMessage={setErrorMessage}
        ref={heightInputRef}
      />
    </CenteredContainer>
  )
}

export default HeightInputStep
