import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { getUserLanguage } from 'Lib'
import Modal from 'Components/Modal'
import Button from 'Components/Button'
import InfoCard from 'Components/InfoCard.component'
import { mapScientificMethodImage } from 'Lib/ImageUtils'

// ---------------------------- BenefitModal ----------------------------

const ScientificMethodImage = styled.img`
  width: 100%;
  object-fit: scale-down;
  align-self: center;
`

interface BenefitModalProps {
  onClose: () => void
}

const BenefitModal: React.FC<BenefitModalProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const lang = getUserLanguage()
  const scientificMethodImage = mapScientificMethodImage(lang)

  return (
    <Modal padded onClose={onClose} scrollable>
      <h3>{t('benefitModal.title')}</h3>
      <p style={{ marginBottom: 0 }}>
        <Trans
          i18nKey='benefitModal.subtitle'
          components={{ strong: <strong /> }}
        />
      </p>
      <ScientificMethodImage src={scientificMethodImage} alt={t('altTexts.scientificMethod') || ''} />
      <InfoCard title={t('benefitModal.infoCardTitle')}>
        <Trans
          i18nKey='benefitModal.infoCardContent'
          components={{ strong: <strong /> }}
        />
      </InfoCard>
      <Button margin='0' onClick={onClose}>{t('gotIt')}</Button>
    </Modal>
  )
}

export default BenefitModal
